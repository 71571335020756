// Link
// ––––––––––––––––––––––––––––––––––––––––––––––––––

a {
  color: $base-link-color;
  text-decoration: $base-link-text-decoration;
  @include anim();
  position: relative;
  border-bottom: 0.1rem solid rgba($base-link-color, 0);
  outline: none !important;

  &:focus,
  &:hover {
    color: $base-link-hover-color;
    text-decoration: $base-link-text-decoration-hover;
    border-bottom: 0.1rem solid $base-link-hover-color;
  }


}
