// office
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.office-list{

}

.office{
  padding-bottom: 4rem;

  &__thumb {
    position: relative;
    display: block;
    margin-bottom: 1rem;

    svg, img {
      //border-radius: $border-radius-base;
      overflow: hidden;
      border-radius: $border-radius-base;


    }
  }

  &__name{
    //display: block;
    font-size: $base-font-size-big;
    line-height: 1;
    font-weight: bold;
    //font-size: $base-font-size-small;



  }


  &__summary{
    margin-top: .7rem;
    //font-size: $base-font-size-small;
    line-height: 1.2;
    color: $color-gray;

  }

  &__info{
    margin-top: .7rem;

    ul{
      margin-bottom: 0;
    }

    &--big{
      font-size: $base-font-size-big;
      line-height: 1;
      font-weight: bold;
    }
  }


  &:hover{
    .office__thumb{
      svg, img{
        //transform: scale(1.05);
        animation: myShopI .75s 1;
      }
    }

  }

}



@-webkit-keyframes myShopI {
  0% {

  }
  25% {
    @include scale(1.025);
    opacity: .9;
  }

  100% {
    @include scale(1);
    opacity: 1;
  }
}

@keyframes myShopI {
  0% {

  }
  25% {
    @include scale(1.025);
    opacity: .9;
  }

  100% {
    @include scale(1);
    opacity: 1;
  }
}