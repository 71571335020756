//
// Pagination (multiple pages)
// --------------------------------------------------
.pages{

  margin: 6rem 0 0 0;

  @include clearfix();

  ul{
    @include list-inline();
    float: left;
    height: $button-height;
    line-height: $button-line-height !important;
    margin-right: 4rem;
  }

  .more{
    display: inline-block;

  }

}


//
