// header
// ––––––––––––––––––––––––––––––––––––––––––––––––––


.header{
  z-index: 900;
  background: $color-initial;
  position: fixed;
  height: 10rem;
  left: 0;
  right: 0;
  top: 0;
  padding: 0 1rem;

  @include anim();


  display: flex;
  align-items: center;

  @media (min-width: $screen-tablet) {
    background: transparent;
  }

  @media (min-width: $screen-desktop) {
    padding: 0 4rem;
  }


  &--white{
    background: rgba($color-initial, 0.95) !important;
  }

  &__nav{
    position: fixed;
    left: 0;
    top: 10rem;
    right: 0;
    bottom: 0;
    background: rgba($color-black, 0.8);
    padding-left: 0;
    list-style: none;

    font-size: $base-font-size-big;

    margin: 0;
    padding: 3rem 0 0 0;
    display: none;

    &__item{
      margin: 2rem;


    }

    &--in{
      display: block;
    }

    @media (min-width: $screen-tablet) {
      display: block;
      position: relative;
      top: auto;
      width: 50%;
      margin-bottom: 0;
      padding: 0;
      background: transparent;

      margin-left: -1rem;


      &__item{
        margin: 0;
        display: inline-block;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  &__main{
    width: 100%;

    padding: 0;
    list-style: none;

    @media (min-width: $screen-tablet) {
      //padding-bottom: 1rem;
    }


    &__item{
      margin: 0;

      display: inline-block;
      padding-left: .5rem;
      padding-right: .5rem;

      @media (min-width: $screen-tablet) {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      @media (min-width: $screen-desktop) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }



      &--burger{
        display: inline-block;
        //display: none;
        @media (min-width: $screen-tablet) {
          display: none;
        }
      }

      &--map{
        display: none;
        //font-size: $base-font-size-small;
        position: relative;

        i{
          position: absolute;
          top: .2rem;
          display: none;
          @media (min-width: $screen-desktop) {
            display: block;
          }
        }

        span{
          line-height: 1.5;
          display: inline-block;


          @media (min-width: $screen-desktop) {
            padding-left: 3.4rem;
          }
        }

        a{
          display: inline-block;
          color: $base-text-color;
          border: none;

          &:hover{
            color: $base-text-color;
            border: none;
          }
        }

        @media (min-width: $screen-tablet) {
          display: inline-block;
        }
      }

      &--phone{

        float: right;

        position: relative;
        height: 4rem;

        @media (min-width: $screen-tablet) {
          float: none;
        }


        div{
          //font-size: $base-font-size-small;
          margin-bottom: .5rem;
        }

        a{
          display: inline-block;
          font-size: 2rem;
          color: $base-text-color;
          border: none;

          &:hover{
            color: $base-text-color;
            border: none;
          }
        }
      }
    }

    //@include list-inline();
    margin-bottom: 0;

    @media (min-width: $screen-tablet) {
      width: 50%;
    }
  }
}
.header--main .header__nav{
  a {
    color: $color-initial;
    text-decoration: $base-link-text-decoration;
    @include anim();
    position: relative;
    border-bottom: 0.1rem solid rgba($color-initial, 0);
    outline: none !important;

    &:focus,
    &:hover {
      color: $color-initial;
      text-decoration: $base-link-text-decoration-hover;
      border-bottom: 0.1rem solid $color-initial;
    }


  }
}
.header--white .header__nav{
  a {
    color: $base-link-color;
    text-decoration: $base-link-text-decoration;
    @include anim();
    position: relative;
    border-bottom: 0.1rem solid rgba($base-link-color, 0);
    outline: none !important;

    &:focus,
    &:hover {
      color: $base-link-hover-color;
      text-decoration: $base-link-text-decoration-hover;
      border-bottom: 0.1rem solid $base-link-hover-color;
    }


  }
}

