// Button
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.button,
.button-pluss
/*button,
input[type='button'],
input[type='reset'],
input[type='submit']*/ {
  background-color: $base-link-color;
  border: 0.1rem solid $base-link-color;
  border-radius: $button-border-radius;
  color: $color-initial;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  height: $button-height;
  line-height: $button-line-height !important;
  padding: $button-padding;
  text-align: center;
  text-decoration: none !important;
  //text-transform: uppercase;
  white-space: nowrap;
  font-size: $button-font-size;
  font-weight: 300 !important;
  outline: none !important;

  padding-right: 8rem;
  position: relative;
  overflow: hidden;

  &:before{
    content: '';
    display: block;
    width: 4rem;
    height: .1rem;
    right: 2rem;

    background: $color-initial;
    top: 50%;
    margin-top: -.1rem;
    position: absolute;
    @include anim();
  }

  &:after{

    content: '';
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -.4rem;
    width: 0;
    height: 0;
    right: 2rem;

    border-top: .4rem solid transparent;
    border-bottom: .4rem solid transparent;

    border-left: .4rem solid $color-initial;
    @include anim();
  }

  @include anim();

  &:focus,
  &:hover {
    background-color: darken($base-link-hover-color, 10%);
    border-color: darken($base-link-hover-color, 10%);
    color: $color-initial;
    outline: 0;

    &:before{
      width: 5rem;
      right: 1rem;
    }

    &:after{
      right: 1rem;
    }
  }

  &[disabled], &.disabled {
    cursor: default;
    background-color: $color-gray;
    border-color: $color-gray;
    color: $color-initial;
    opacity: 0.5;

    &:focus,
    &:hover {
      background-color: $color-gray;
      border-color: $color-gray;
    }
  }



  &.button-outline {
    background-color: transparent;
    color: $color-primary;
    border-color: rgba($color-primary, 0.3);

    &:before{
      background: rgba($color-primary, 0.3);
    }

    &:after{
      border-left: .6rem solid rgba($color-primary, 0.3);
    }

    &:focus,
    &:hover {
      background-color: transparent;
      border-color: $color-primary;
      color: $color-primary;

      &:before{
        background: rgba($color-primary, 1);
      }

      &:after{
        border-left: .6rem solid rgba($color-primary, 1);
      }
    }

    /*&[disabled], &.disabled {
      &:focus,
      &:hover {
        border-color: rgba($color-primary, 0.3);
        color: $color-primary;
      }
    }*/
  }



  &.button-invert {
    background-color: $color-gray;
    border-color: $color-gray;
    color: $color-initial;

    &:focus,
    &:hover {
      background-color: darken($color-gray, 10%);
      border-color:  darken($color-gray, 10%);
      color: $color-initial;
    }

    /*&[disabled], &.disabled {
      color: $color-primary;
      &:focus,
      &:hover {
        color: $color-primary;
      }
    }*/
  }


  &.button-block {
    width: 100%;
    display: block !important;
    text-align: center;
  }

  &.button-width {
    padding: 0 6rem;
  }


}
