//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  font-size: $base-font-size-small2;
  padding: 2rem 0 2rem 0;
  //margin-bottom: $line-height-computed;
  list-style: none;
  background-color: $breadcrumb-bg;
  border-radius: $border-radius-base;
  margin-bottom: 0;
  color: $color-gray;

  &__item {
    display: inline-block;

    max-width: 20rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: none;

    &:nth-last-child(2){
      display: inline-block;
    }

    &:before {
      content: $breadcrumb-separator;
      padding: 0 .5rem 0 0.2rem;
      color: $breadcrumb-color;
    }

    &:first-child{
      /*display: inline-block;*/
      &:before {
        display: none;
      }
    }

    @media (min-width: $screen-tablet) {
      display: inline-block;
    }

    a{
      color: $color-gray;
      border: none;

      &:hover{
        border: none;
        color: $base-link-color;
      }
    }


  }

  > .active {
    color: $breadcrumb-active-color;
  }

  @media (min-width: $screen-tablet) {
    padding: 2rem 0 2rem 0;
  }

}
