// Color
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$color-initial: #fff !default;
$color-primary: #BD9B62 !default;
$color-secondary: #444 !default;
$color-tertiary: #f7f9fa !default;
$color-quaternary: #d1d1d1 !default;
$color-quinary: #e1e1e1 !default;
$color-white: #fff !default;
$color-black: #000 !default;
$color-gray: #989da5 !default;
$color-bg: #f5f7f9 !default;
//$color-bg: #e7ecef !default;


//$color-primary-light: #f9f9f9 !default;
//$color-primary-light: #f5f5f5 !default;
$color-primary-light: #f8f7f7 !default;
$color-success: #2fb457 !default;
$color-danger: #e3000e !default;
$color-warning: #fea94e !default;

//
// Base
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$base-selection-bg: $color-primary;
$base-selection-color: $color-initial;
$base-selection-opacity: .85;


$base-text-color: $color-secondary;
$base-font-family: 'Montserrat', 'Roboto Condensed', -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; // !!!!;
$base-font-size: 1.6rem;
$base-font-size-small: 1.4rem;
$base-font-size-small2: 1.2rem;
$base-font-size-big: 2rem;
$base-font-size-lead: 2rem;
$base-font-weight: 300;
$base-letter-spacing: normal;
//$base-line-height: 1.45;
$base-line-height: 1.47;


$line-height-computed: floor(($base-font-size * $base-line-height));
$border-radius-base: 0; //0.4rem;
$padding-base-vertical: 0.6rem;
$padding-base-horizontal: 1.2rem;
$line-height-base: $base-line-height;



// Headers
// ––––––––––––––––––––––––––––––––––––––––––––––––––
$base-header-color: $color-black;
$base-header-font-family: $base-font-family;
$base-header-font-weight: bold;
$base-header-letter-spacing: normal;
$base-header-line-height: 1.1;

$base-header-h1-mobile: 3.2rem;
$base-header-h2-mobile: 2.4rem;
$base-header-h3-mobile: 2rem;
$base-header-h4-mobile: 1.8rem;
$base-header-h5-mobile: 1.6rem;
$base-header-h6-mobile: 1.4rem;

$base-header-h1: 5.4rem;
$base-header-h2: 2.8rem;
$base-header-h3: 2.4rem;
$base-header-h4: 2rem;
$base-header-h5: 1.6rem;
$base-header-h6: 1.4rem;

$bstrong-font-weight: 500;
$iem-style: normal;


// Links
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$base-link-color: darken($color-primary, 10%);
$base-link-hover-color: darken($color-primary, 10%);
$base-link-text-decoration: none;
$base-link-text-decoration-hover: none;



// Buttons
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$button-height: 4.8rem;
$button-line-height: 4.6rem;
$button-padding: 0 2rem;
$form-padding: 0 1.4rem;
$button-border-radius: $border-radius-base;
$button-font-size: $base-font-size;


//== Pagination
$cursor-disabled:                not-allowed;

$pagination-color:                     $color-primary;
$pagination-bg:                        #fff;
$pagination-border:                    #ddd;

$pagination-hover-color:               $color-primary;
$pagination-hover-bg:                  darken($color-tertiary, 10%);
$pagination-hover-border:              darken($color-tertiary, 10%);

$pagination-active-color:              #fff;
$pagination-active-bg:                 $color-primary;
$pagination-active-border:             $color-primary;

$pagination-disabled-color:            $color-gray;
$pagination-disabled-bg:               #fff;
$pagination-disabled-border:           #ddd;


//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical: 0;
$breadcrumb-padding-horizontal: 0;
//** Breadcrumb background color
$breadcrumb-bg: transparent;
//** Breadcrumb text color
$breadcrumb-color: $color-gray;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color: $base-header-color;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator: "\2014";

$zindex-modal-background:  1040 !default;
$zindex-modal:             1050 !default;
$zindex-tooltip:           1070 !default;


//== Tooltips
//
//##
$zindex-tooltip:           1070;

//** Tooltip max width
$tooltip-max-width:           200px !default;
//** Tooltip text color
$tooltip-color:               #fff !default;
//** Tooltip background color
$tooltip-bg:                  #000 !default;
$tooltip-opacity:             .9 !default;

//** Tooltip arrow width
$tooltip-arrow-width:         5px !default;
//** Tooltip arrow color
$tooltip-arrow-color:         $tooltip-bg !default;


//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding:         2rem 3rem 6rem 3rem !default;

//** Padding applied to the modal title
$modal-title-padding:         2rem 3rem !default;
//** Modal title line-height
$modal-title-line-height:     $line-height-base !default;

//** Background color of modal content area
$modal-content-bg:                             #fff !default;
//** Modal content border color
$modal-content-border-color:                   rgba(0,0,0,.2) !default;
//** Modal content border color **for IE8**
$modal-content-fallback-border-color:          #999 !default;

//** Modal backdrop background color
$modal-backdrop-bg:           #000 !default;
//** Modal backdrop opacity
$modal-backdrop-opacity:      .5 !default;
//** Modal header border color
$modal-header-border-color:   #e5e5e5 !default;
//** Modal footer border color
$modal-footer-border-color:   $modal-header-border-color !default;

$modal-lg:                    900px !default;
$modal-md:                    600px !default;
$modal-sm:                    300px !default;



// Larger than mobile screen

$grid-max-width: 1120px;
$grid-max-width: 1448px;
$grid-max-width: 1968px;
//$grid-max-width: 2650px;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  960px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1120 !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         20px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;



$enable-grid-classes:       true !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           20px !default;

// END of Grid Options


$screen-phone: 0 !default;
$screen-tablet: 576px !default;
$screen-desktop: 768px !default;
$screen-lg-desktop: 992px !default;

//




