// Expromtum
// ––––––––––––––––––––––––––––––––––––––––––––––––––

// lebedev forms
.field{
  position: relative;
  margin-bottom: 1.5rem;



  label{
    /*font-weight: normal;*/
    //font-size: $font-size-small;
  }

  ins{
    text-decoration: none !important;
  }
  .number_control{

  }


  &.invalid, &.required{
    label, .form-control {
      color: $color-danger !important;
    }

    &.changed{
      input, select{
        border-color: $color-danger;
      }
    }

  }

  /*&.unrequired{
    label, .form-control {
      color: $base-header-color
    }
  }*/

  .show_on_blured_and_invalid, .show_on_blured_and_required{
    display: none;
    font-size: 1.2rem;
    color: $color-danger;
    padding: 0.2rem 0 0 0;
  }

  &.blured.invalid {
    .show_on_blured_and_invalid, .show_on_blured_and_required {
      display: block;
    }
  }

  &.valid,  &.unrequired{
    input, select{
      border-color: $color-success;
    }
  }


  @media (min-width: $screen-tablet) {
    &.nom{
      margin-bottom: 0;
    }
  }


}