// logo
// ––––––––––––––––––––––––––––––––––––––––––––––––––


.logo{

  position: relative;
  width: 3.6rem;
  height: 4rem;
  margin: 0;
  padding: 0;
  overflow: hidden;

  &__symbol{
    display: block;
    fill: $color-primary;
    position: absolute;
    left:0;
    top: 0;
  }

  &__text{
    display: block;
    fill: $color-black;
    position: absolute;
    left: 4.6rem;
    top: .8rem;
  }

  @media (min-width: 1060px) {
    width: 14rem;
  }
}



