// Preloader
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$preloaderOverlayBg: rgba($color-initial, 0.7);
$preloaderZIndex: 2030;
$preloaderLineColor: $color-primary;
$preloaderSize: 40px;
$preloaderBorder: 2px;

#preloader{
  position: fixed;
  z-index: $preloaderZIndex;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: $preloaderOverlayBg;

  &.full{
    background: $color-initial;
    z-index: 900;
  }

  //pointer-events: none;
  user-select: none;

}

//

.circle-preloader  {
  -webkit-animation: semi-rotate 1s linear infinite;
  animation: semi-rotate 1s linear infinite;


  $sizeHalf: floor($preloaderSize/2);
  //@margin = @sizeHalf * -1;

  overflow: hidden;

  width: $sizeHalf;
  height: $preloaderSize;
  left: 50%;
  top: 50%;
  margin-top: -($sizeHalf);
  margin-left: -($sizeHalf);

  position: fixed;
  z-index: 2000;

  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;

  &:before, &:after {
    content: '';
    top: 0;
    left: 0;
    right: -100%;
    bottom: 0;
    border: $preloaderBorder solid currentColor;
    border-color: currentColor transparent transparent currentColor;
    border-radius: 50%;
    position: absolute;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: inner-rotate 0.5s linear infinite alternate;
    animation: inner-rotate 0.5s linear infinite alternate;
    color: $preloaderLineColor;
  }

  &:after {
    opacity: 0;
  }

}



@-webkit-keyframes inner-rotate {
  to {
    opacity: 1;
    -webkit-transform: rotate(115deg);
    transform: rotate(115deg);
  }
}

@keyframes inner-rotate {
  to {
    opacity: 1;
    -webkit-transform: rotate(115deg);
    transform: rotate(115deg);
  }
}
@-webkit-keyframes semi-rotate {
  from {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  45% {
    -webkit-transform: rotate(198deg);
    transform: rotate(198deg);
  }
  55% {
    -webkit-transform: rotate(234deg);
    transform: rotate(234deg);
  }
  to {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg);
  }
}
@keyframes semi-rotate {
  from {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  45% {
    -webkit-transform: rotate(198deg);
    transform: rotate(198deg);
  }
  55% {
    -webkit-transform: rotate(234deg);
    transform: rotate(234deg);
  }
  to {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg);
  }
}



