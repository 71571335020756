// alerts
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$alert-base-color: $base-text-color;
$alert-base-bg: $color-tertiary;

$alert-success-color: $color-initial;
$alert-success-bg: $color-success;

$alert-danger-color: $color-initial;
$alert-danger-bg: $color-danger;

$alert-info-color: $base-text-color;
$alert-info-bg: #ffeb9c;

.alert{
  position: relative;
  //font-size: 1.6rem;
  border-radius: $border-radius-base;

  padding: 1rem 4.4rem 1rem 1.4rem;
  margin: 0 0 2rem 0;

  color: $base-text-color;
  background: $color-tertiary;
  //border: 0.1rem solid darken($color-tertiary, 5%);

  &.alert-noround{
    border-radius: 0;
  }



  p{
    margin: 0;
    padding: 0;
  }

  &--success{
    color: $alert-success-color;
    background: $alert-success-bg;
    //border: 0.1rem solid darken($alert-success-bg, 5%);
  }

  &--danger{
    color: $alert-danger-color;
    background: $alert-danger-bg;
    //border: 0.1rem solid darken($alert-danger-bg, 5%);
  }

  &--info{
    color: $alert-info-color;
    background: $alert-info-bg;
    //border: 0.1rem solid darken($alert-info-bg, 5%);
  }
}