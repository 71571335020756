// Utility
// ––––––––––––––––––––––––––––––––––––––––––––––––––

// Clear a float with .clearfix
.clearfix {
  &:after {
    clear: both;
    content: " ";
    display: table;
  }
}

// Float either direction
.float-left {
  float: left;
}

.float-right {
  float: right;
}


.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}


.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hidden{
  display: none;
}


.tbl {
  display: table;
  width: 100%;
  height: 100%;

  >.td {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
}

.lead{
  font-size: $base-font-size-lead;
  margin-bottom: 4rem;
  line-height: 1.5;

  font-family: $base-header-font-family;
  font-weight: 300;

  p{
    margin-bottom: 1rem;
  }

  &.nom{
    margin-bottom: 0 !important;
  }
}

.text-big{
  font-size: $base-font-size-big;
}

.list-unstyled{
  @include list-unstyled;
}

.list-inline{
  @include list-inline()
}


