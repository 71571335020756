// Typography
// ––––––––––––––––––––––––––––––––––––––––––––––––––

b,
strong {
  font-family: $base-header-font-family;
  font-weight: $bstrong-font-weight;
}

small, .small {
  font-size: $base-font-size-small;
}

.small2 {
  font-size: $base-font-size-small2;
}


i,
em {
  font-style: $iem-style;
}

p {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6 .h6 {
  //
  font-family: $base-header-font-family;
  color: $base-header-color;
  font-weight: $base-header-font-weight;
  letter-spacing: $base-header-letter-spacing;
  line-height: $base-header-line-height;
  margin-bottom: 2rem;
  margin-top: 6rem;
}

h1, .h1 {
  font-size: $base-header-h1-mobile;
  margin-top: 0;
}

h2, .h2 {
  font-size: $base-header-h2-mobile;
}

h3, .h3 {
  font-size: $base-header-h3-mobile;
}

h4, .h4 {
  font-size: $base-header-h4-mobile;
}

h5, .h5 {
  font-size: $base-header-h5-mobile;
}

h6, .h6 {
  font-size: $base-header-h6-mobile;
}

// Larger than mobile screen
@media (min-width: $screen-tablet) {
  h1, .h1 {
    font-size: $base-header-h1;
  }

  h2, .h2 {
    font-size: $base-header-h2;
  }

  h3, .h3 {
    font-size: $base-header-h3;
  }

  h4, .h4 {
    font-size: $base-header-h4;
  }

  h5, .h5 {
    font-size: $base-header-h5;
  }

  h6, .h6 {
    font-size: $base-header-h6;
  }
}


h1.light, .h1.light,
h2.light, .h2.light,
h3.light, .h3.light,
h4.light, .h4.light,
h5.light, .h5.light,
h6.light .h6.light
{
  font-weight: 300;
}