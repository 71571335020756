// Table
// ––––––––––––––––––––––––––––––––––––––––––––––––––

table {
  border-spacing: 0;
  width: 100%;
  //max-width: 100%;
  //overflow-x: auto;

  tr{
    td,
    th {
      border-bottom: 0.1rem solid $color-quinary;
      padding: 1.2rem 1.5rem;
      text-align: left;

      line-height:  1.4;
      vertical-align: top;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }


    &:last-child{
      td,
      th {
        border-bottom: none;
      }
    }
  }


  &.table-hover{
    tr{
      @include anim();
      &:hover{
        background: $color-tertiary;
      }
    }
  }
  &.table-condensed{
    tr{
      td,
      th {
        padding: 0.5rem 1.5rem;
        text-align: left;
        line-height:  1.4;
      }

    }
  }
}

