// body
// ––––––––––––––––––––––––––––––––––––––––––––––––––

html{
  margin: 0;
  padding: 0;
  //display: block;

  position: relative;
  min-height: 100%;
}
body{
  margin: 0;
  padding: 0;
  margin-top: 10rem;
  margin-bottom: 12rem;

  //background: #eee;

  @media (min-width: $screen-tablet) {
    //padding-top: 14rem;
  }

  @media (min-width: $screen-desktop) {
    //padding-top: 14rem;
  }

  //background: $color-primary;

}





.container-fluid{
  @media (min-width: $screen-desktop) {
    padding-left: 40px;
    padding-right: 40px;
  }
}




.text-gray{
  color: $color-gray;
}

