// footer
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.footer{
  font-size: $base-font-size-small;
  color: $color-gray;

  padding: 2rem 0 4rem 0;

  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 10rem;
}

