// Spacing
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.button,
button,
dd,
dt,
li {
  margin-bottom: 1rem;
  line-height: 1.1;
}

fieldset,
input,
select,
textarea {
  margin-bottom: 0;
}

figure{
  margin: 0;
}

blockquote,
dl,
figure,
form,
ol,
p,
pre,
table,
ul {
  margin-bottom: 2rem;
}

