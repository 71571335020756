// Mygrid
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}