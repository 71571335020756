// submenu
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.submenu{
  padding-left: 0;
  list-style: none;
  margin-left: -0.5rem;

  &__item{
    display: inline-block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &--active{
      a{
        cursor: default;
        color: $base-text-color;
        border-bottom-color: transparent;

        &:hover{
          color: $base-text-color;
          border-bottom-color: transparent;
        }
      }
    }
  }
}