// Mixins
// ––––––––––––––––––––––––––––––––––––––––––––––––––

@mixin anim($seconds: 0.3s, $for: all) {
  @include transition($for $seconds ease);

}

@mixin selection($color, $text, $alfa: .5){
  ::selection {
    background: rgba($color, $alfa);
    color: $text;
    text-shadow: none;
  }
  ::-moz-selection {
    background: rgba($color, $alfa);
    color: $text;
    text-shadow: none;
  }
}



@mixin list-unstyled {
  padding-left: 0;
  list-style: none;
}


@mixin list-inline($p: .5rem) {
  @include list-unstyled();
  margin-left: $p * -1;

  > li {
    display: inline-block;
    padding-left: $p;
    padding-right: $p;
  }
}

@mixin list-float($p: .5rem) {
  @include list-unstyled();
  margin-left: $p * -1;
  &:after{
    @include clearfix;
  }

  > li {
    display: block;
    padding-left: $p;
    padding-right: $p;
    float: left;
  }
}


@mixin lego($t: 0, $l: 0, $url: 'lego.svg') {
  background: url($url) no-repeat $l $t;
}

// Single side border-radius

@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}
@mixin border-right-radius($radius) {
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
}
@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}
@mixin border-left-radius($radius) {
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}