// page
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.page{
  padding: 0 0 8rem;
}



.subheader{


  &__title{
    color: $color-primary;
    margin-bottom: .7rem;
  }
  &__text{

    color: $color-black;

    &--big{
      font-size: $base-font-size-big;
    }

    &--bold{
      font-weight: bold;
    }
  }
}


.meter{
  color: $color-primary;
  position: relative;
  font-weight: bold;
  line-height: 1;


  &__num {
    font-size: 8rem;

    @media (min-width: $screen-tablet) {
      font-size: 15rem;
    }

    &--small{
      font-size: 8rem;

      @media (min-width: $screen-tablet) {
        font-size: 10rem;
      }
    }
  }

  &__subname{
    font-weight: normal;
    font-size: $base-font-size-small;
  }

  &__symbol{
    position: absolute;


    @media (min-width: $screen-tablet) {
      font-size: 3rem;
    }
  }



}



