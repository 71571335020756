// hero
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.hero{
  position: relative;
  margin-top: -10rem;
  width: 100%;
  background-color: $color-quaternary;
  height: 60rem;

  background-image: url(001.jpg);
  background-size: cover;
  background-position: top;
  background-position: center;

  @media (min-width: $screen-tablet) {
    height: 80rem;
  }


  &__bg{
    position: absolute;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.8) 100%);
    height: 15rem;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: block;

    &--bottom{
      top: auto;
      bottom: 0;
      height: 20rem;

      background: rgb(0,0,0);
      background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
    }
  }

  &__part{
    background-color: $color-initial;
    position: absolute;

    left: 2rem;
    right: 2rem;
    top: 12rem;
    bottom: auto;

    z-index: 40;

    @media (min-width: $screen-tablet) {
      left: 50%;
      margin-left: -4rem;
      right: 4rem;
      top: 0;
      bottom: 0;
    }
  }

  &__cont{
    padding: 2rem 2rem 2rem 2rem;

    @media (min-width: $screen-tablet) {
      padding: 15rem 4rem 0 4rem;
    }
  }

  .h1{
    line-height: 1.3;
    margin-bottom: 4rem;
  }
}


.p-info{
  position: absolute;
  width: 50%;
  bottom: 6rem;
  left: 10%;
  z-index: 50;

  display: none;

  @media (min-width: 920px) {
    display: block;
  }

  &--right{
    left: 50%;
  }

}
