// Burger
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.menu-hamburger{
  position: relative;
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  display: block;
  border: none !important;

  @media (min-width: $screen-tablet) {
    //margin-left: 1.4rem;
  }
  @media (min-width: $screen-desktop) {
    //display: none;
  }
}


.burger{
  cursor: pointer;
  user-select: none;
  position: absolute;
  left: 0;
  top: 0.2rem;
  width: 2.4rem;
  //line-height: 3.2rem;
  //height: 3.2rem;
  display: inline-block;
  background: transparent !important;
  padding: 0;
  border-radius: 0 !important;
  border: none;

  //position: absolute;

  &__lines {
    position: relative;
    display: inline-block;
    width: 2.4rem;
    height: .2rem;
    top: -.3rem;
    //margin-top: -.4rem;
    background: $base-header-color;

    @include anim();

    &:before, &:after {
      position: absolute;
      display: inline-block;
      left: 0;
      content: '';
      width: 2.4rem;
      height: .2rem;
      background: $base-header-color;
      @include anim(.3s);
    }

    &:before {
      top: .6rem;
    }

    &:after {
      top: -.6rem;
    }

  }



  &--close{
    @include anim(.3s);
  }
  &--close:hover{
    @include rotate(180deg);
  }



}


.header-nav-open{
  .burger{
    @include anim(.3s);


    .lines {
      @include anim(.5s);
      background: transparent;

      &:before, &:after {
        @include anim(.3s);
        top: -.1rem;
        //background: $color-primary;
      }

      &:before {
        @include rotate(45deg);
      }

      &:after {
        @include rotate(-45deg);
      }
    }

    &:hover{
      @include rotate(180deg);
    }
  }
}