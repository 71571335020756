// demand
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.demand-phone{
  div{
    //font-size: $base-font-size-small;
    margin-bottom: .5rem;
  }

  a{
    display: inline-block;
    font-size: 2rem;
    color: $base-text-color;
    border: none;

    &:hover{
      color: $base-text-color;
      border: none;
    }
  }
}

.demand-email{
  margin-top: 2rem;
  font-size: 2rem;

  a{
    display: inline-block;
    font-size: 2rem;
    color: $base-text-color;
    border: none;

    &:hover{
      color: $base-text-color;
      border: none;
    }
  }
}

.demand-fast{
  padding: 4rem;
  border: solid 1rem $color-primary;
}