// Blockquote
// ––––––––––––––––––––––––––––––––––––––––––––––––––

blockquote {
  border-left: 0.3rem solid $color-quaternary;
  margin-left: 0;
  margin-right: 0;
  padding: 1rem 1.5rem;

  *:last-child {
    margin-bottom: 0;
  }
}
