// Form
// ––––––––––––––––––––––––––––––––––––––––––––––––––



input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
textarea,
select {
  appearance: none; // Removes awkward default styles on some inputs for iOS
  background-color: $color-initial;
  border: .1rem solid $color-quaternary;
  border-radius: $button-border-radius;
  box-shadow: none;
  box-sizing: inherit; // Forced to replace inherit values of the normalize.css
  height: $button-height;
  //line-height: $button-line-height !important;
  padding: $form-padding;
  font-weight: 300;
  width: 100%;
  display: block;
  background: $color-initial;
  font-size: $base-font-size;
  font-family: $base-font-family;


  @include anim();
  @include placeholder($color-gray);

  &:focus {
    border-color: $color-primary;
    outline: 0;
  }

  &.inline{
    display: inline-block;
    width: auto;
  }
}

select {

  background: $color-initial url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="48"><path d="M19.1,5.4c0-0.1,0-0.2-0.1-0.2l-0.4-0.4c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0.1l-3.5,3.5l-3.5-3.5 C11.1,4.7,11,4.6,11,4.6c-0.1,0-0.2,0-0.2,0.1l-0.4,0.4c-0.1,0.1-0.1,0.1-0.1,0.2s0,0.2,0.1,0.2l4.2,4.2c0.1,0.1,0.1,0.1,0.2,0.1 c0.1,0,0.2,0,0.2-0.1L19,5.6C19.1,5.5,19.1,5.5,19.1,5.4z" fill="#333"/></svg>') center right no-repeat;


  padding-right: $button-height;
  //background-color: $color-initial;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;

  font-size: $base-font-size;
  font-family: $base-font-family;
  &:focus {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="48"><path d="M19.1,5.4c0-0.1,0-0.2-0.1-0.2l-0.4-0.4c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0.1l-3.5,3.5l-3.5-3.5 C11.1,4.7,11,4.6,11,4.6c-0.1,0-0.2,0-0.2,0.1l-0.4,0.4c-0.1,0.1-0.1,0.1-0.1,0.2s0,0.2,0.1,0.2l4.2,4.2c0.1,0.1,0.1,0.1,0.2,0.1 c0.1,0,0.2,0,0.2-0.1L19,5.6C19.1,5.5,19.1,5.5,19.1,5.4z" fill="#1d53b9"/></svg>');
  }
}

textarea {
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-height: 6.5rem;
  //line-height: 1.1 !important;
}

label,
legend {
  display: block;
  //font-size: 1.6rem;
  //font-weight: 700;
  font-family: $base-header-font-family;
  margin-bottom: 0.5rem;
  color: $base-header-color;
}


fieldset {
  border-width: 0;
  padding: 0;
}

input[type='checkbox'],
input[type='radio'] {
  display: inline;
}

.label-inline {
  display: inline-block;
  //font-weight: normal;
  margin-left: 0.5rem;
}




